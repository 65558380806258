import React, { useState } from 'react';
import { Container, Row, Col, Button, Form, Dropdown } from 'react-bootstrap';
import DownloadIcon from "../../images/download.svg";
import { HOST2 } from '../../Api/api';
import axios from 'axios';
import { toast } from 'react-toastify';
import { UploadCloud, ArrowRight, ChevronLeft } from 'react-feather';
import { useNavigate } from 'react-router-dom';

function FileUpload() {
    const [fileError, setFileerror] = useState({});//to handle validation
    const [excel_file, setExcel_file] = useState(null);//file to upload
    const [file_path, setFile_path] = useState("");//path of the uploaded file
    const [loading, setLoading] = useState(false);//handle loader
    const [upload, setUpload] = useState("");//to show sucessfully upload
    const [transformer, setTransformer] = useState("Gemini");//Value of the transformer
    const [category, setCategory] = useState("")//to select category
    const [subCategory, setSubcategory] = useState("")//to select subcategory
    const [submitloading, setSubmitloading] = useState(false)//to manage loader while submiting

    const sub_category = ["Adapter", "Audio Accessories", "Batteries", "Cable", "Camcorder", "Camera", "Camera Accessories",
         "Camera Bag", "Camera Lens", "Charger", "Computer Accessories", "Computer Component", "Computer Software", "Cooling Pad",
        "Desktop", "Extension Cord", "Gaming Accessories", "Gaming Chair", "Gaming Console", "Gaming Controller", "Headset", "Home Alarm",
         "Home Audio", "Home Automation", "Home Safe", "Home Theatre Projector", "Intercom", "Keyboard", "Keyboard Guard", "Laptop", "Laptop Bag",
        "Laptop Stand", "Memory Card", "Mobile Mount", "Mobile Phone", "Mobile Screen Guard", "Monitor", "Mouse", "Mousepad", "Networking Device",
         "Pendrive", "Photo Printer", "Power Bank", "Printer", "Printer Accessories", "Projector", "Projector Accessories", "Projector Mount",
        "Projector Screen", "Remote", "Screen Protector", "Selfie Stick", "Smart Band", "Smart Lightning", "Smart Switch", "Smart TV", "Smart Watch"]
    
    const navigate = useNavigate();

    //defining the file size and type
    const FILE_SIZE = 10 * 1024 * 1024; // 10 MB in bytes
    const SUPPORTED_FORMATS = ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet", "application/vnd.ms-excel"];

    //function to upload file
    const handleFilechange = async (e) => {
        const file = e.target.files[0];
        if (!file) {
            return;
        }
        // Check file format
        if (!SUPPORTED_FORMATS.includes(file.type)) {
            setFileerror({ ...fileError, "file": "Unsupported file format. Only .xls and .xlsx files are allowed." });
            return;
        }
        // Check file size
        if (file.size > FILE_SIZE) {
            setFileerror({ ...fileError, "file": "File size exceeds the 10MB limit." });
            return;
        }
        // If validation passes
        setFileerror({ ...fileError, "file": null }); // Clear any previous errors
        setExcel_file(file);
        setLoading(true);
        try {
            const fileData = new FormData();
            fileData.append("file", file)
            const response = await axios.post(HOST2 + "/upload_user_file", fileData, {
                headers: {
                    "Accept": "application/json",
                },
            });
            if (response.status === 200) {
                toast.success("File uploaded successfully" || response?.data?.message);
                setUpload("File Uploaded Successfully");
                setFile_path(response.data.file_path);
                console.log(response.data.message);
                console.log(response.data.file_path);
            }
        }
        catch (error) {
            toast.error(error.response?.data?.message || "Error uploading file");
        }
        finally {
            setLoading(false);
        }
    };

    //fumction to submit
    const handleSubmit = async () => {
        if (!file_path) {
            toast.warn("Please upload a file.");
            return;
        }
        if (!category) {
            toast.warn("PLease select category");
            return;
        }
        if (!subCategory) {
            toast.warn("Please select sub-category");
            return;
        }
        try {
            setSubmitloading(true);
            const response = await axios.post(HOST2 + "/generate_product_data",
                { "transformer": transformer, "file_path": file_path, "Category": category, "Sub_Category": subCategory }, {
                headers: {
                    'Content-Type': 'application/json',
                    Accept: 'application/json',
                }
            });
            if (response.status === 200) {
                toast.success(response?.data?.message || "Data generated sucessfully");
            }
        }
        catch (error) {
            toast.error(error.response?.data?.message || "Something went wrong");
        }
        finally {
            setSubmitloading(false);
        }
    }

    return (
        <Container fluid className="d-flex flex-column justify-content-center vh-auto overflow-hidden bg-light">
            <div className="col-lg-1 pt-3 px-5">
              <button className='border-leads-blue bg-white rounded-pill p-2 d-flex justify-content-center align-items-center' onClick={()=>{navigate("/table")}}>
                <ChevronLeft size={24} color='#007CC2' />
                <span className='mx-2 text-leads-blue fw-semibold'>Back</span>
              </button>
            </div>
            <h2 className="my-4 text-center text-black ">Tech Bzar Catalog-AI Generator</h2>
            <p className="text-center px-3 mb-2 text-secondary">
                Tech Bzar Catalog - AI-Driven Generator for Cutting-Edge Product Listings.
            </p>
            {/* Dropdown for Transformer Selection */}
            <Row className='justify-content-between align-items-center'>
                <Col md={3} xs={12} className="mb-4">
                    <Form.Label
                        className="form-label fw-bold font-14"> Select Transformer </Form.Label>
                    <Form.Select
                        aria-label="Select Transformer"
                        className="selectDropDown fs-6 border rounded text-secondary fw-medium"
                        onChange={(e) => setTransformer(e.target.value)}
                        value={transformer}
                        style={{ width: '100%' }} // Ensures the dropdown takes full width
                    >
                        <option disabled selected value="" className='d-none'>Select Transformer</option>
                        {/* <option disabled className='d-none'>Select Transformer</option> */}
                        <option value="Chatgpt">ChatGPT</option>
                        <option value="Gemini">Gemini </option>
                    </Form.Select>
                </Col>
                {/* <Col md={3} lg={2} className=''>
                    <Button className="ButtonDesign w-100 font-500 font-16 py-2 d-flex align-items-center justify-content-center" onClick={()=>{navigate("/table")}}>Proceed
                        <ArrowRight color='#fff' size={20} className='ms-1'/>
                    </Button>
                </Col> */}
            </Row>

            {/* Main Section */}
            <div className="d-flex w-100 h-100 p-0 m-0">
                {/* History Section */}
                <div className="w-25  border overflow-auto text-center bg-white rounded-start">
                    <h5 className="border-bottom p-3">History</h5>
                    {/* Add history items here */}
                </div>

                {/* Form Section */}
                <div className="w-75 d-flex flex-column align-items-center border bg-white overflow-auto rounded-end p-5 justify-content-center">

                    {/* Upload Button Row */}
                    <Row className="mb-4 text-center w-100 justify-content-center px-2">
                        <Col xs={12} md={12}>
                            <div className=" d-flex flex-column justify-content-center align-items-center dragdrop rounded fw-medium fs-5 py-2 cursor-pointer" onClick={() => document.getElementById("upload_excel").click()}>
                                <UploadCloud color='#007CC2' size={100} />
                                {loading ? (
                                    <div className="spinner-border spinner-border-sm text-light" role="status" style={{ width: '1.5rem', height: '1.5rem' }}></div>
                                ) : (
                                    <span className='text-leads-blue font-14 fw-bold'>Browse Files</span>
                                )}
                            </div>
                            <input id="upload_excel" className="d-none" type="file" accept=".xls,.xlsx" onChange={handleFilechange} />
                        </Col>
                        {/* <Col xs={12} md={4} className="d-flex align-items-center justify-content-left text-muted">
                            {excel_file ? excel_file.name : "No file selected"}
                        </Col> */}
                    </Row>
                    {/* <Row>
                        <Col xs={12} md={4} className=" w-auto d-flex align-items-center justify-content-center text-muted">
                            <p> {excel_file ? excel_file.name : `No file selected`}</p>
                        </Col>
                    </Row> */}
                    {fileError.file && <div className="text-danger text-center mb-3">{fileError.file}</div>}

                    {/* Category & Sub-category Row */}
                    <Row className="mb-4 w-100 justify-content-center align-items-end" >
                        <Col xs={12} md={4}>
                            <Form.Label className='fw-bold font-14'>
                                Category
                            </Form.Label>
                            < Form.Select aria-label="Category" className="selectDropDown fs-6 p-2" onChange={(e) => setCategory(e.target.value)} placeholder='hey'>
                                <option disabled selected value="" className='d-none'>Select Category</option>
                                <option value="Electronic Accessories">Electronic Accessories</option>
                            </Form.Select>
                        </Col>
                        <Col xs={12} md={4}>
                            <Form.Label className='fw-bold font-14'>
                                Sub-Category
                            </Form.Label>
                            <Form.Select aria-label="Sub-Category" className="selectDropDown fs-6 p-2" onChange={(e) => setSubcategory(e.target.value)}>
                                <option disabled selected value="" className='d-none'>Select Sub-Category</option>
                                {
                                    sub_category.map((option, index) => (
                                        <option key={index} value={option}>{option}</option>
                                    ))
                                }
                            </Form.Select>
                        </Col>
                        <Col xs={12} md={4}>
                            <Button className="ButtonDesign w-100 fw-medium py-2" onClick={handleSubmit}>
                                {
                                    submitloading ? (
                                        <div className="spinner-border spinner-border-sm text-light" role="status" style={{ width: '1.5rem', height: '1.5rem' }}></div>
                                    ) : (
                                        "Generate"
                                    )}
                            </Button>
                        </Col>
                    </Row>

                    {/* Success Message */}
                    <Row className='py-3'>
                        <Col className="text-center">
                            <span className="text-success fw-bold fs-6" >{upload}</span>
                        </Col>
                    </Row>
                </div>
            </div>
        </Container>
    );
}

export default FileUpload;
